




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REVERT_CHANGE } from '@/modules/conservatorship/store/actions';
import { HistoryEntry, RevertChange } from '../../../types';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorship');

@Component({})
export default class RevertChangeDialog extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Object }) historyEntry!: HistoryEntry | null;

  @Conservatorship.Action(REVERT_CHANGE) revertChange!: (params: RevertChange) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: RevertChange | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:historyEntry', null);
    }, 200);
  }

  @Watch('historyEntry')
  watchOutbox(historyEntry: HistoryEntry | null) {
    if (!historyEntry) return;

    this.value = {
      conservatorship_id: historyEntry.conservatorshipId,
      changed: historyEntry.changed,
      type: historyEntry.type,
      value: historyEntry.value
    };

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  revert() {
    if (!this.value) return {};

    this.error = null;

    return this.revertChange(this.value);
  }
}
