




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { FETCH_HISTORY } from '@/modules/conservatorship/store/actions';
import { GET_HISTORY } from '@/modules/conservatorship/store/getters';
import { ConservatorshipDetails, FetchDetails, HistoryEntry, HistoryType } from '../../../types';
import { ApiResponse, Pagination } from '@/components/types';
import CardRow from '@/components/CardRow.vue';
import { TranslateResult } from 'vue-i18n';
import RevertChangeDialog from '../dialogs/RevertChangeDialog.vue';
import AppTable from '@/components/AppTable.vue';
import CardHeader from '@/components/CardHeader.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    CardHeader,
    AppTable,
    RevertChangeDialog,
    CardRow
  }
})
export default class HistoryCard extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(FETCH_HISTORY) fetchHistory!: (params: FetchDetails) => Promise<ApiResponse>;
  @Conservatorship.Getter(GET_HISTORY) getHistory!: (conservatorship_id: string) => HistoryEntry[];

  loading: boolean = false;
  search: string = '';
  pagination: Pagination = { sortBy: ['changed'], descending: true };
  revertEntry: HistoryEntry | null = null;

  get history() {
    return this.getHistory(this.conservatorship.id);
  }

  get items() {
    return this.history
      .map(({ type, content, ...entry }: HistoryEntry) => ({
        ...entry,
        type,
        content,
        transType: this.$i18n.t(`label.history.${type}`),
        transContent: this.value({ content, type }),
        date: format(entry.changed, 'dd.MM.yyyy')
      }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('label.changed'), value: 'changed' },
      { align: 'left', text: this.$i18n.t('common.type'), value: 'transType' },
      { align: 'left', text: this.$i18n.t('label.newValue'), value: 'transContent' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false }
    ];
  }

  @Watch('conservatorship', { immediate: true })
  onConservatorshipChange(conservatorship: ConservatorshipDetails) {
    return this.fetch(conservatorship.id);
  }

  value({ content, type }: { content: string, type: HistoryType }): string | TranslateResult {
    if (type === 'state') {
      return this.$i18n.t(`conservatorship.state.${content}`);
    }

    if (type === 'financial') {
      return this.$i18n.t(`conservatorship.financialState.${content}`);
    }

    if (type === 'placement') {
      return this.$i18n.t(`conservatorship.placement.${content}`);
    }

    if (['closed', 'suspended', 'open', 'archived'].includes(type) === true) {
      return this.$i18n.t(`conservatorship.state.${content}`);
    }

    return content;
  }

  async fetch(id: string) {
    this.loading = true;

    try {
      await this.fetchHistory({ id });
    } catch (error) {
      console.warn(error);
      this.$router.replace({ name: 'conservatorship-list' });
    }

    this.loading = false;
  }
}
