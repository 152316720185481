


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import HistoryCard from '@/modules/conservatorship/components/details/cards/HistoryCard.vue';

@Component({
  components: { HistoryCard }
})
export default class HistoryTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
